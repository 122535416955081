<template>
  <div
    class="resource-item-wrap"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
  >
    <div class="resource-item">
      <div
        class="resource-item__header"
        :class="{ 'show-border': showContent }"
        @click="getResourceDetail"
      >
        <div class="left">
          <img
            :src="require(`@/assets/images/${item.fileType}.png`)"
            class="filetype"
          />
          <h1
            class="resource-title"
            v-html="
              (data.highs && data.highs.filename && data.highs.filename[0]) ||
                item.filename
            "
          />
        </div>
        <div class="other-info">
          <p class="time">{{ item.utime.substr(0, 10) }}</p>
        </div>
      </div>
      <div
        class="resource-detail-wrap"
        v-if="
          item.filelist &&
            item.filelist.length > 0 &&
            item.isdir === 1 &&
            showContent
        "
      >
        <p
          v-for="(detailItem, detailIndex) in item.filelist"
          class="detail-item-wrap"
          :key="`detail${detailIndex}`"
        >
          <span
            class="detail-item-title"
            v-html="
              (data.highs &&
                data.highs['filelist.filename'] &&
                data.highs['filelist.filename'][detailIndex]) ||
                detailItem.filename
            "
          ></span>
          <span v-if="detailItem.isdir !== 1">
            - {{ detailItem.size | bytesToSize }}</span
          >
        </p>
        <p class="detail-item-wrap" v-if="item.filelist.length > 5">
          <span class="">......</span>
        </p>
      </div>
      <div class="resource-meta" v-if="showContent">
        <div class="left">
          <span class="meta-item">
            文件共计：{{ item.size | bytesToSize }}
          </span>
          <span class="meta-item image-wrap" v-if="item.type">
            <img :src="`https://res.hexiaotu.com/pan/icons/${item.type}.png`" />
          </span>
          <a
            class="report-button danger"
            :href="`http://report.3453k.cn?fid=${id}&type=${type}`"
            target="_blank"
            >举报资源</a
          >
        </div>
        <div class="right">
          <div class="check-wrap">
            <p class="checking-tip" v-if="isCheckingUrlValid">
              正在检查该链接是否有效...
            </p>
            <p class="result-tip" v-if="!isCheckingUrlValid">
              <span class="valid" v-if="valid == 1">该链接有效，可以访问</span>
              <span class="invalid" v-if="valid == -1"
                >该链接已经失效，无法访问</span
              >
            </p>
          </div>
          <span class="meta-item copy-item" v-if="pwd" @click.stop="copy(pwd)">
            提取码:{{ pwd }}
            <span class="copy">点击复制</span>
          </span>
          <van-button type="primary" size="small" @click="openUrl"
            >前往网盘下载</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkUrlFromBaidu, getDetail } from "../api/search";
import copyToClipboard from "../utils/clipboard";
const formatUrl = (url) => {
  if (!url) {
    return "";
  }
  const index = url.lastIndexOf("http");
  if (index > 0) {
    return url.slice(index);
  } else {
    return url;
  }
};

export default {
  props: ["data"],
  data() {
    return {
      pwd: "",
      url: "",
      valid: 0,
      loading: false,
      showContent: false,
      isCheckingUrlValid: false,
    };
  },
  computed: {
    item() {
      return this.data.res;
    },
    id() {
      return this.item.id || this.item.Id;
    },
    parent() {
      return this.item.parent;
    },
    type() {
      return this.item.type;
    },
  },
  methods: {
    copy(content) {
      copyToClipboard(content);
      this.$message.success("复制成功");
    },
    async checkUrlValid(id) {
      this.isCheckingUrlValid = true;
      const res = await checkUrlFromBaidu(id);
      if (res) {
        this.valid = res[id];
      }
      this.isCheckingUrlValid = false;
    },
    openUrl() {
      return this.openWindow(this.url, true);
    },
    async getResourceDetail() {
      if (this.loading || this.showContent) return;
      this.loading = true;
      try {
        if (this.id) {
          const detail = await getDetail({
            id: this.id,
            type: this.type,
            parent: this.parent,
            size: 15,
          });
          if (detail && detail.filename) {
            this.pwd = detail.pwd;
          }
          if (detail && detail.url) {
            this.url = formatUrl(detail.url);
            if (
              this.type === "aliyundrive" &&
              this.parent &&
              this.parent != "undefined"
            ) {
              this.url = `${this.url}/folder/${this.parent}`;
            }
            if (this.type === "baidu") {
              if (this.url.includes("?")) {
                this.url = `${this.url}&pwd=${this.pwd}`;
              } else {
                this.url = `${this.url}?pwd=${this.pwd}`;
              }
            }
          }
        }
        this.showContent = true;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
        this.checkUrlValid(this.id);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_var.scss";
.resource-item {
  padding: 15px 20px;
  border-radius: 6px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  mark {
    color: lighten($theme-color, 5%);
    background-color: transparent;
    text-decoration: underline;
  }
  .resource-item__header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.show-border {
      padding-bottom: 15px;
      border-bottom: 1px solid #eee;
    }
    .left {
      flex: auto;
      display: flex;
      align-items: center;
    }
    .filetype {
      height: 26px;
      margin-right: 15px;
      flex: none;
    }
    h1 {
      word-break: break-all;
      font-size: 16px;
      color: #333;
      line-height: 1.3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .other-info {
      flex: none;
      min-width: 120px;
      .time {
        text-align: right;
      }
    }
  }
  .resource-detail-wrap {
    margin-top: 15px;
    padding: 10px;
    border-radius: 2px;
    background-color: #f8f8f8;
    width: 100%;
    .detail-item-wrap {
      span {
        font-size: 13px;
        display: inline;
        line-height: 1.4;
        color: #666;
      }
      .detail-item-title {
        font-weight: normal;
        position: relative;
        padding-left: 15px;
        display: inline;
        word-break: break-all;
        &:before {
          content: "|-";
          position: absolute;
          top: -2px;
          left: 0;
        }
      }
    }
  }
  .resource-meta {
    margin-top: 15px;
    font-size: 12px;
    color: #555;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left,
    .right {
      display: flex;
      align-items: center;
    }
    .report-button {
      margin-left: 10px;
      font-size: 12px;
      text-decoration: none;
    }
    .van-button {
      height: 30px;
      border-radius: 4px;
    }
    .check-wrap {
      font-size: 12px;
      margin-right: 6px;
      .checking-tip {
        color: #666;
      }
      .result-tip {
        .valid {
          color: #41b035;
        }
        .invalid {
          color: red;
        }
      }
    }
    .meta-item {
      display: inline-flex;
      align-items: center;
      margin-right: 6px;
      border-radius: 4px;
      padding: 5px 10px;
      min-height: 30px;
      border: 1px solid #ddd;
      &.copy-item {
        padding: 0;
        padding-left: 10px;
      }
      img {
        height: 18px;
      }
      .copy {
        cursor: pointer;
        color: $theme-color;
        padding: 5px 10px;
        background-color: rgba($theme-color, 0.2);
        border-left: 1px solid #ddd;
        transition: all ease 0.2s;
        font-weight: bold;
        margin-left: 6px;
      }
    }
  }
}

@media (max-width: 700px) {
  .resource-item {
    padding: 12px 10px;
    .resource-item__header {
      h1 {
        font-size: 15px;
      }
      .filetype {
        height: 24px;
        margin-right: 6px;
      }
      .other-info {
        min-width: auto;
        font-size: 12px;
      }
    }
    .resource-detail-wrap {
      margin-top: 8px;
      font-size: 11px;
    }
    .resource-meta {
      flex-direction: column;
      .right {
        flex-wrap: wrap;
        justify-content: center;
        .check-wrap {
          width: 100%;
          text-align: center;
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
